import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Post from '../components/Post'
import Sidebar from '../components/Sidebar'

class IndexRoute extends React.Component {
  render() {
    const items = []
    const { title, subtitle } = this.props.data.site.siteMetadata
    const posts = this.props.data.allMarkdownRemark.edges
    posts.forEach(post => {
      items.push(<Post data={post} key={post.node.fields.slug} />)
    })

    return (
      // <Layout>
      //   <div>
      //     <Helmet>
      //       <title>{title}</title>
      //       <meta name="description" content={subtitle} />
      //     </Helmet>
      //     <Sidebar {...this.props} />
      //     <div className="content">
      //       <div className="content__inner">{items}</div>
      //     </div>
      //   </div>
      // </Layout>
      <div className="layout">
        <h1>Jimmy Cook</h1>
        <div>
          <h2>Who am I?</h2>
          <p>
            I'm a <a href="https://github.com/WJimmyCook">software developer</a>
            . I used to be a professional online poker player. I spent a few
            years{' '}
            <a href="https://www.darpa.mil/about-us/timeline/blast-gauge">
              working
            </a>{' '}
            in Afghanistan. I'm a{' '}
            <a href="https://www.youtube.com/watch?v=EEhQunjr_Hc">
              skateboarder
            </a>
            , weight-lifter, bicycle-rider, and a recovering{' '}
            <i>guy-who-cant-remember-peoples-names</i>.
          </p>
          <p>
            This simplistic website is a refelction of my desire to live a
            minimalist life.
          </p>
          <h2>What am I doing?</h2>
          <p>
            My current labor of love is a React Native app called{' '}
            <a href="https://itunes.apple.com/us/app/ultimate-531-workout-log/id1437949461?mt=8">
              Ultimate 531
            </a>
            .
          </p>
          <p>
            I don't have an allegiance to any specific programming language but
            I'm really enjoying all things JavaScript at the moment.
          </p>
          <h2>Where am I from?</h2>
          <p>Ohio native, I now live in Durham, NC.</p>
          <h2>What are my favorite books?</h2>
          <span>My top 10 in no particular order</span>
          <ul>
            <li>Sapiens by Yuval Noah Harari</li>
            <li>Deep Work by Cal Newport</li>
            <li>When by Daniel H. Pink</li>
            <li>The Power of Habit by Charles Duhigg</li>
            <li>
              A Guide to the Good Life: The Ancient Art of Stoic Joy by William
              B. Irvine
            </li>
            <li>Tribe: On Homecoming and Belonging by Sebastian Junger</li>
            <li>A Short History of Nearly Everything by Bill Bryson</li>
            <li>The War of Art by Steven Pressfield</li>
            <li>Liar's Poker by Michael Lewis</li>
            <li>Whitey Bulger by Kevin Cullen and Shelly Murphy</li>
          </ul>
          <h2>Contact me?</h2>
          <p>
            You can reach me at{' '}
            <a href="mailto:wjimmycook@gmail.com">wjimmycook@gmail.com</a>
          </p>
        </div>
      </div>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email

          github
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`
